$(document).foundation();
      if(matchMedia){
        var matchingMediumAndLargeScreens = matchMedia('only all and (min-width: 680px)');
        if(matchingMediumAndLargeScreens && matchingMediumAndLargeScreens.matches){
          $('.animation-wrapper)').waypoint(function(direction){
            if(direction === 'down'){
              $(this).addClass('is-animating');
            }
          }, { offset: '0%' });
        }
      }  
